import React, { useEffect, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'

import Script from 'next/script'

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

export const IntercomAPI = (...args) => {
  if (canUseDOM && window.Intercom) {
    window.Intercom.apply(null, args)
  } else {
    console.warn('Intercom not initialized yet')
  }
}

const Intercom = ({ appID = 'u3f7fydw', user }) => {
  const intercomSettings = useMemo(() => {
    const settings = {
      professional: {
        'Eu sou': 'Psicólogo',
        CRP: user?.prof_register,
        Endereco: user?.address?.street,
        Cidade: user?.address?.city,
        Estado: user?.address?.state,
        Slug: user?.slug,
        Status: user?.status_subscription,
        Plano: user?.plan
      },
      patient: {
        'Eu sou': 'Paciente',
        Endereco: user?.address ? user?.address?.street : null,
        Cidade: user?.address ? user?.address?.city : null,
        Estado: user?.address ? user?.address?.state : null,
        Status: user?.status,
        'contract company': user?.contract?.company,
        origin: user?.origin
      },
      default: {
        name: user?.name,
        email: user?.email,
        user_hash: user?.intercom_hash,
        phone: user?.full_phone,
        Sexo: user?.gender,
        CPF: user?.cpf,
        'Data de nascimento': user?.birthday,
        Newsletter: user?.newsletter
      }
    }
    return {
      app_id: appID,
      custom_launcher_selector: '#open_intercom',
      ...(user && { ...settings.default, ...settings[user.role] })
    }
  }, [user, appID])

  const onLoadIntercomSettings = useCallback(() => {
    if (!window.Intercom || !canUseDOM) {
      return
    }
    window.intercomSettings = intercomSettings
    window.Intercom('boot', window.intercomSettings)
  }, [intercomSettings])

  useEffect(() => {
    if (!window.Intercom || !canUseDOM) {
      return
    }
    window.intercomSettings = intercomSettings
    window.Intercom('update', intercomSettings)
  }, [user])

  return (
    <Script
      id='intercom'
      strategy='lazyOnload'
      src={`https://widget.intercom.io/widget/${appID}`}
      onLoad={onLoadIntercomSettings}
    />
  )
}

export default connect(store => ({ user: store.auth.user }))(Intercom)
